import { useCallback } from 'react';

import data from '../../package.json';

/**
 * @var {string} siteName  The site name from the description property in packege.json
 */
const siteName = data.description;

/**
 * useMeta Hook
 *
 * Update the page meta data :
 * - The html tag lang attribute
 * - The title tag
 * - The meta tags with data-meta attributes equal to title|description|image|lang|url
 *
 * The site name comes from the description property in package.json
 *
 * @return {function}  The hook callback function
 */
const useMeta = () => {
  /**
   * setMeta callback function
   *
   * @param {object} metaData  The meta data
   * @param {string} metaData.title  The title
   * @param {string} metaData.description  The description
   * @param {string} metaData.image  The image
   * @param {string} metaData.lang  The language
   * @param {string} metaData.url  The page url
   */
  const setMeta = useCallback(({ description, image, lang, ...metaData }) => {
    const title = `${metaData.title} - ${siteName}`;
    const url = metaData.url.startsWith('http')
      ? metaData.url
      : `${window.location.protocol}//${window.location.host}${metaData.url}`;
    const headElt = document.querySelector('head');

    /** Title */
    const titleElt = headElt.querySelector('title');
    titleElt.innerHTML = title;
    for (const metaTitleElt of headElt.querySelectorAll('meta[data-meta="title"]')) {
      metaTitleElt.setAttribute('content', title);
    }

    /** Description */
    for (const metaDescriptionElt of headElt.querySelectorAll('meta[data-meta="description"]')) {
      metaDescriptionElt.setAttribute('content', description);
    }

    /** Image */
    for (const metaImageElt of headElt.querySelectorAll('meta[data-meta="image"]')) {
      metaImageElt.setAttribute('content', image || `${process.env.REACT_APP_APP_URL}social.png`);
    }

    /** Lang */
    if (lang !== undefined) {
      const htmlElt = document.querySelector('html');
      htmlElt.setAttribute('lang', `${lang}-${lang.toUpperCase()}`);
      for (const metaLocaleElt of headElt.querySelectorAll('meta[data-meta="lang"]')) {
        metaLocaleElt.setAttribute('content', `${lang}_${lang.toUpperCase()}`);
      }
    }

    /** Url */
    for (const metaUrlElt of headElt.querySelectorAll('meta[data-meta="url"]')) {
      metaUrlElt.setAttribute('content', url);
    }
  }, []);

  /** Return the hook callback function */
  return setMeta;
};

export { useMeta };
export default useMeta;
