import PropTypes from 'prop-types';

/**
 * <Container />
 */

const Container = ({ layout, className, children }) => (
  <div className={`container ${layout ? `l-${layout}` : ''} ${className}`}>{children}</div>
);

Container.propTypes = {
  layout: PropTypes.oneOf(['large', 'narrow']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
Container.defaultProps = {
  className: '',
};

export default Container;
