import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const postsSlice = createSlice({
  name: 'events',
  initialState: {
    isLoaded: false,
    list: [],
    error: '',
    current: {},
    perPage: 8,
    currentPage: 1,
  },
  reducers: {
    /**
     * Save the events list
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object[]} action.payload  The events list
     */
    saveList: (state, action) => {
      state.isLoaded = true;
      state.list = action.payload;
    },

    /**
     * Save the loading error
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the current event
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The current event
     */
    saveCurrent: (state, action) => {
      state.current = action.payload;
    },

    /**
     * Save the number of list items to display per page
     *
     * @param {Object} state  The Redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The number of items to display per page
     */
    savePerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 1;
    },

    /**
     * Save the current page index
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {number} action.payload  The current page index
     */
    saveCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

export const { saveList, saveError, saveCurrent, savePerPage, saveCurrentPage } = postsSlice.actions;

/**
 * Return the events list filtered by a given language
 *
 * @param {string} language  The events language
 * @return {object}
 */
export const selectList = createSelector(
  (state) => state.events,
  (_, language) => language,
  (events, language) => events.list.filter((event) => event.lang === language)
);

/**
 * Return a slice the events list filtered by a given language
 *
 * @param {string} language  The events language
 * @param {number} start  The slice start index
 * @param {number} length  The slice length
 * @return {object}
 */
export const selectSlice = createSelector(
  (state) => state.events,
  (_, language) => language,
  (_, __, start) => start,
  (_, __, ___, length) => length,
  (events, language, start, length) =>
    events.list.filter((event) => event.lang === language).slice(start || 0, length ? (start || 0) + length : undefined)
);

/**
 * Return the number of pages the events list.
 *
 * @return {number}  The number of pages
 */
export const selectNumberPages = createSelector(
  (state) => state.events,
  (_, language) => language,
  (events, language) => Math.ceil(events.list.filter((event) => event.lang === language).length / events.perPage)
);

export default postsSlice.reducer;
