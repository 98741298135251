import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectPage } from '../redux/pagesSlice';

/** Hooks */
import { useNavigateWithLoader } from '../hooks/useNavigateWithLoader';

/** Components */
import Background from '../components/Background';
import Container from '../components/Container';
import Button from '../components/Button';
import Link from '../components/Link';
import BeeGreen from '../components/BeeGreen';

const Footer = (props) => {
  const { navigate } = useNavigateWithLoader();
  const { t: __, i18n } = useTranslation();

  const pageContact = useSelector((state) => selectPage(state, 'contact', i18n.language));
  const pageLegal = useSelector((state) => selectPage(state, 'legal', i18n.language));

  return (
    <footer className="layout footer">
      <Background layout="stamp-outline" color="cedar">
        <Container>
          <div className="content footer-content">
            <div className="content-section">&copy; {new Date().getFullYear()} Royal Philatelic Club Arlonais</div>
            <div className="content-section">
              <Button className="footer-button" onPress={() => navigate(pageContact.link, 'gold')}>
                {__('footer.Contact us')}
              </Button>
              <span role="separator"> &bull; </span>
              <Button className="footer-button" onPress={() => navigate(pageLegal.link, 'tortilla')}>
                {__('footer.Privacy policy')}
              </Button>{' '}
            </div>
            <div className="content-section">
              {__('footer.Creation of the website')}&nbsp;:{' '}
              <Link className="footer-author" url="https://bee-green.eco" target="_blank">
                <BeeGreen />
                <span className="visually-hidden">Bee Green</span>
              </Link>
            </div>
          </div>
        </Container>
      </Background>
    </footer>
  );
};

export default Footer;
