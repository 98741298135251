import { useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

/** Redux */
import { saveList as savePagesList, saveError as savePagesError } from './redux/pagesSlice';
import { saveList as saveEventsList, saveError as saveEventsError } from './redux/eventsSlice';
import { saveList as saveNoveltiesList, saveError as saveNoveltiesError } from './redux/noveltiesSlice';
import { saveOptions, saveError as saveOptionsError } from './redux/optionsSlice';
import { saveUser } from './redux/userSlice';

/** Utils */
import './util/CustomResize';
import { responseStatus } from './util/Fetch';
import { getCookie } from './util/Cookies';

/** Components */
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import PagesRouter from './components/PagesRouter';
import Loader from './components/Loader';

/** Styles */
import './fonts/icomoon.scss';
import './App.scss';

const App = () => {
  // const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const isPagesListLoaded = useSelector((state) => state.pages.isLoaded);
  const isEventsListLoaded = useSelector((state) => state.events.isLoaded);
  const isNoveltiesListLoaded = useSelector((state) => state.novelties.isLoaded);

  /**
   * Verify autodetected language and update it if necessary
   */
  // useEffect(() => {
  //   const i18nLanguage = i18n.language.substring(0, 2);
  //   if (!i18n.languages.includes(i18nLanguage)) {
  //     /** Set the language to 'fr' if the detected language is not supported */
  //     i18n.changeLanguage('fr');
  //   } else if (i18n.language.length > 2) {
  //     /** Format the detected language to a two-letter language code */
  //     i18n.changeLanguage(i18nLanguage);
  //   }
  //   /** Redirect the page to the language folder */
  //   if (i18n.language !== 'fr' && window.location.pathname === '/') {
  //     window.location.replace(`${window.location.href + i18n.language}/`);
  //   }
  // }, [i18n]);

  /**
   * Fetch the user data from the user cookie and save it to Redux
   */
  useEffect(() => {
    const userCookie = getCookie(process.env.REACT_APP_USER_COOKIE);
    if (userCookie) {
      const user = JSON.parse(userCookie);
      user.logged && dispatch(saveUser(user));
    }
  }, [dispatch]);

  /**
   * Fetch the pages list and save it to Redux
   */
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_REST_URL}pages?${new URLSearchParams({
        orderby: 'menu_order',
        order: 'asc',
        per_page: 100,
      }).toString()}`,
      { headers: { Accept: 'application/json' } }
    )
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => dispatch(savePagesList(response)))
      .catch((error) => dispatch(savePagesError(error.message)));
  }, [dispatch]);

  /**
   * Fetch the events list and save it to Redux
   */
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_REST_URL}events?${new URLSearchParams({
        orderby: 'date',
        order: 'desc',
        per_page: 100,
      }).toString()}`,
      { headers: { Accept: 'application/json' } }
    )
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => dispatch(saveEventsList(response)))
      .catch((error) => dispatch(saveEventsError(error.message)));
  }, [dispatch]);

  /**
   * Fetch the novelties list and save it to Redux
   */
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_REST_URL}novelties?${new URLSearchParams({
        orderby: 'date',
        order: 'desc',
        per_page: 12,
      }).toString()}`,
      { headers: { Accept: 'application/json' } }
    )
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => dispatch(saveNoveltiesList(response)))
      .catch((error) => dispatch(saveNoveltiesError(error.message)));
  }, [dispatch]);

  /**
   * Fetch the contacts option page and save it to redux
   */
  useEffect(() => {
    fetch(`${process.env.REACT_APP_REST_URL}options`, {
      headers: { Accept: 'application/json' },
    })
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => dispatch(saveOptions(response)))
      .catch((error) => dispatch(saveOptionsError(error.message)));
  }, [dispatch]);

  /**
   * Add development tools
   */
  const DevTools = process.env.NODE_ENV === 'development' ? lazy(() => import('./Dev')) : <></>;

  return (
    <>
      <AppLoader />
      <BrowserRouter>
        {isPagesListLoaded && isEventsListLoaded && isNoveltiesListLoaded && (
          <>
            <Header />
            <PagesRouter />
            <Footer />
          </>
        )}
      </BrowserRouter>
      <Suspense fallback={<></>}>{process.env.NODE_ENV === 'development' && <DevTools />}</Suspense>
    </>
  );
};

const AppLoader = () => {
  const isLoaded = useSelector((state) => state.app.isContentLoaded);
  const loaderColor = useSelector((state) => state.app.loaderColor);

  return <Loader color={loaderColor} className="app-loader" isLoaded={isLoaded} />;
};

export default App;
