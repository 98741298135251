import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: 0,
    name: '',
    email: '',
    token: '',
    searches: [],
    favourites: [],
  },
  reducers: {
    /**
     * Save the user.
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The user Object
     * @param {Object} action.payload.id  The user id
     * @param {Object} action.payload.name  The user name
     * @param {Object} action.payload.email  The user e-mail
     * @param {Object} action.payload.token  The application token
     */
    saveUser: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.token = action.payload.token;
    },

    /**
     * Delete the user.
     *
     * @param {Object} state  The redux state
     */
    deleteUser: (state) => {
      state.id = 0;
      state.name = '';
      state.email = '';
      state.token = '';
    },

    /**
     * Save the loading error.
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the user's searches.
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The user's searches
     */
    saveSearches: (state, action) => {
      state.searches = action.payload;
    },

    /**
     * Append a search to the user's searches.
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The new search
     */
    appendSearch: (state, action) => {
      state.searches.unshift(action.payload);
    },

    /**
     * Update a property from a user's search.
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload
     * @param {number} action.payload.id  The search id
     * @param {string} action.payload.key  The property key to update
     * @param {any} action.payload.value  The new value
     */
    updateSearchProperty: (state, action) => {
      state.searches.find((search) => search.id === action.payload.id)[action.payload.key] = action.payload.value;
    },

    /**
     * Save the user's favourite searches.
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The user's favourite searches
     */
    saveFavourites: (state, action) => {
      state.favourites = action.payload;
    },
  },
});

export const { saveUser, deleteUser, saveError, saveSearches, appendSearch, updateSearchProperty, saveFavourites } =
  userSlice.actions;

/**
 * Return whether the user is logged in.
 *
 * @return {object}
 */
export const isLogged = createSelector(
  (state) => state.user,
  (user) => user.id !== 0 && user.name !== '' && user.email !== '' && user.token !== ''
);

/**
 * Return a slice the user's searches list.
 *
 * @param {number} start  The start index
 * @param {number} length  The slice length
 * @return {Object}  The user searches slice
 */
export const selectSearchesSlice = createSelector(
  (state) => state.user,
  (_, start) => start,
  (_, __, length) => length,
  (user, start, length) => user.searches.slice(start, start + length)
);

/**
 * Return a slice the user's favourite searches list.
 *
 * @param {number} start  The start index
 * @param {number} length  The slice length
 * @return {Object}  The user searches slice
 */
export const selectFavouritesSlice = createSelector(
  (state) => state.user,
  (_, start) => start,
  (_, __, length) => length,
  (user, start, length) => user.favourites.slice(start, start + length)
);

export default userSlice.reducer;
