import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

/**
 * <Loader />
 */

const Loader = ({ isLoaded, color, className }) => {
  const { t: __ } = useTranslation();

  const [isLoaderHidden, setIsLoaderHidden] = useState(false);

  useEffect(() => {
    setIsLoaderHidden(false);
    if (isLoaded) {
      /** Add a delay to match the css transition in _loader.scss */
      setTimeout(() => {
        setIsLoaderHidden(true);
      }, 600);
    }
  }, [isLoaded, setIsLoaderHidden]);

  return (
    <>
      {!isLoaderHidden && (
        <div className={`loader c-${color} ${className} ${isLoaded ? 'is-loaded' : ''}`}>
          <div className="loader-content">
            {__('app.Loading')}
            <span className="loader-dot">.</span>
            <span className="loader-dot">.</span>
            <span className="loader-dot">.</span>
          </div>
        </div>
      )}
    </>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['ruby', 'teal', 'peacock', 'gold', 'cedar', 'tortilla', 'sand', 'cream']),
  isLoaded: PropTypes.bool.isRequired,
};
Loader.defaultProps = {
  className: '',
};

export default Loader;
