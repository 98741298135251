import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * useFormValidation Hook
 *
 * Validate a form.
 *
 * @param {HTMLElement} form  The form to validate
 * @param {string[]} requiredFields  The required fields
 * @return {function}  The hook callback function
 */
const useFormValidation = (form, requiredFields) => {
  const { i18n } = useTranslation();
  const __ = i18n.getFixedT(i18n.language, null, 'forms');

  /**
   * validateForm callback function
   * @return {Array}  result
   * @return {Bool}   result.0  Whether the form is valid
   * @return {Object} result.1  An object containing the form errors
   */
  const validateForm = useCallback(() => {
    const formData = new FormData(form);
    const errors = {};
    requiredFields.forEach((field) => {
      if (!formData.has(field) || formData.get(field).length === 0) {
        errors[field] = __('This field is required');
      } else if (field === 'email' && !formData.get(field).match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        errors[field] = __('The email address is invalid');
      }
    });
    const isValid = Object.values(errors).filter((value) => value.length > 0).length === 0;
    return [isValid, errors];
  }, [form, requiredFields, __]);

  /** Return the hook callback function */
  return validateForm;
};

export { useFormValidation };
export default useFormValidation;
