/**
 * Return the string for the SameSite cookie parameter
 *
 * @returns {string}
 */
const sameSite = () => (process.env.NODE_ENV === 'development' ? '; SameSite=None' : '');

/**
 * Return the string for the Secure cookie parameter
 *
 * @returns {string}
 */
const secure = () => {
  return process.env.NODE_ENV !== 'development' && process.env.REACT_APP_PUBLIC_URL.startsWith('/') ? '; Secure' : '';
};

/**
 * Set a cookie
 *
 * @param {string} name  The cookie name
 * @param {string} value  The cookie value
 * @param {number} days  The cookie duration in days
 */
export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; Expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; Path=/${sameSite()}${secure()}`;
};

/**
 * Get a cookie
 *
 * @param {string} name  The cookie name
 * @returns {string|null}
 */
export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

/**
 * Delete a cookie
 *
 * @param {string} name  The cookie name
 */
export const deleteCookie = (name) => {
  document.cookie = `${name}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/${sameSite()}${secure()}`;
};
