import { useRef, useEffect, useState } from 'react';

/** Utils */
import { responseStatus } from '../util/Fetch';

/**
 * useCsrfToken Hook
 *
 * Get a csrf token from the app.
 *
 * @param {string} tokenId  The token identifier
 * @return {string}  The csrf token
 */
const useCsrfToken = (tokenId) => {
  const [token, setToken] = useState('');
  const isLoading = useRef(false);

  /** Fetch the token from the app */
  useEffect(() => {
    if (!isLoading.current) {
      isLoading.current = true;
      fetch(`${process.env.REACT_APP_REST_URL}token`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'X-CSRF-TOKEN': tokenId,
        },
      })
        .then(responseStatus)
        .then((response) => response.json())
        .then((response) => setToken(response.token))
        .catch(console.error);
    }
  }, [isLoading, tokenId, setToken]);

  /** Return the token */
  return token;
};

export { useCsrfToken };
export default useCsrfToken;
