import PropTypes from 'prop-types';

export const pageListItemPropTypes = PropTypes.shape({
  /** Added fields */
  intro: PropTypes.string, // page, event
  api_url: PropTypes.string.isRequired,
  /** Rest fields */
  id: PropTypes.number.isRequired,
  date: PropTypes.string, // novelty, event, search
  slug: PropTypes.string, // novelty
  link: PropTypes.string, // page, event
  title: PropTypes.string.isRequired,
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }), // page, event
  parent: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }), // page, event
  acf: PropTypes.oneOfType([PropTypes.array, PropTypes.object]), // novelty, event, search
  lang: PropTypes.string.isRequired,
  translations: PropTypes.objectOf(PropTypes.string), // page, event
}).isRequired;

export const pageContentPropTypes = PropTypes.shape({
  /** Added fields */
  meta_data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
  }), // page, event
  /** Rest fields */
  title: PropTypes.string.isRequired,
  acf: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  lang: PropTypes.string.isRequired,
  translations: PropTypes.objectOf(PropTypes.string), // page, event
}).isRequired;

const propTypes = {
  page: pageListItemPropTypes,
  content: pageContentPropTypes,
};

export default propTypes;
