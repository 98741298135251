import PropTypes from 'prop-types';
import { forwardRef, Fragment } from 'react';
import { useButton } from '@react-aria/button';

/** Components */
import Icon from './Icon';

/**
 * <Button />
 */

const Button = forwardRef((props, buttonRef) => {
  const { buttonProps } = useButton(
    {
      ...props,
      onPressStart: (e) => e.target.classList.add('is-pressed'),
      onPressEnd: (e) => e.target.classList.remove('is-pressed'),
    },
    buttonRef
  );
  const { layout, color, cursor, className, children, icon, iconLayout, disabled } = props;
  const ariaProps = {
    'aria-label': props.ariaLabel || null,
  };

  const shouldBeWrapped = layout === 'stamp' && color && color !== 'cream';
  const Wrapper = shouldBeWrapped ? 'span' : Fragment;
  const WrapperProps = shouldBeWrapped ? { className: 'button-text' } : {};

  const classNames = ['button'];
  layout && classNames.push(`l-${layout}`);
  color && classNames.push(`c-${color}`);
  cursor && classNames.push(`curs-${cursor}`);
  className && classNames.push(className);
  disabled && classNames.push('is-disabled');

  return (
    <button
      ref={buttonRef}
      {...buttonProps}
      {...ariaProps}
      className={classNames.join(' ')}
      onMouseEnter={(e) => props.onMouseEnter(e)}
      onMouseLeave={(e) => props.onMouseLeave(e)}
      disabled={disabled}
    >
      <Wrapper {...WrapperProps}>{children ? children : icon && <Icon icon={icon} layout={iconLayout} />}</Wrapper>
    </button>
  );
});

Button.displayName = 'Button';

/**
 * `children` and `icon` props can't be set together.
 * Don't forget to provide an `ariaLabel` prop if you provide the `icon` prop.
 */
Button.propTypes = {
  layout: PropTypes.oneOf(['plain', 'stamp', 'plain-stamp']),
  color: PropTypes.oneOf(['ruby', 'teal', 'peacock', 'gold', 'cedar', 'cream']),
  cursor: PropTypes.oneOf(['press', 'up']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.string,
  iconLayout: PropTypes.string, // @see Icon.propTypes
  onPress: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
};
Button.defaultProps = {
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  disabled: false,
};

export default Button;
