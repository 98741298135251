import { configureStore } from '@reduxjs/toolkit';

import appReducer from './appSlice';
import userReducer from './userSlice';
import optionsReducer from './optionsSlice';
import pagesReducer from './pagesSlice';
import eventsReducer from './eventsSlice';
import noveltiesReducer from './noveltiesSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    options: optionsReducer,
    pages: pagesReducer,
    events: eventsReducer,
    novelties: noveltiesReducer,
  },
});
