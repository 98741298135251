import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTreeState } from '@react-stately/tree';
import { useMenu, useMenuItem } from '@react-aria/menu';
import { useFocus } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';

/**
 * <Menu />
 */

const Menu = ({ color, className, ...props }) => {
  const menuRef = useRef();
  const menuState = useTreeState({ ...props, selectionMode: 'none' });
  const { menuProps } = useMenu(props, menuState, menuRef);

  return (
    <ul ref={menuRef} {...menuProps} className={`menu ${className} ${color ? `c-${color}` : ''}`}>
      {[...menuState.collection].map((item) => (
        <MenuItem key={item.key} item={item} menuState={menuState} onAction={props.onAction} />
      ))}
    </ul>
  );
};

Menu.propTypes = {
  color: PropTypes.oneOf(['ruby', 'teal', 'peacock', 'gold', 'cedar', 'cream']),
  className: PropTypes.string,
  onAction: PropTypes.func.isRequired,
};
Menu.defaultProps = {
  className: '',
};

/**
 * <MenuItem />
 */

const MenuItem = ({ item, menuState, onAction }) => {
  const menuItemRef = useRef();
  const [isFocused, setFocused] = useState(false);
  const { focusProps } = useFocus({ onFocusChange: setFocused });
  const { menuItemProps } = useMenuItem({ key: item.key, onAction }, menuState, menuItemRef);

  return (
    <li ref={menuItemRef} {...mergeProps(menuItemProps, focusProps)} className={isFocused ? 'has-focus' : ''}>
      {item.rendered}
    </li>
  );
};

MenuItem.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    rendered: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  }),
  menuState: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default Menu;
