/**
 * <BeeGreen />
 */

const BeeGreen = () => {
  return (
    <svg
      className="beegreen"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 96.81 23.79"
    >
      <g id="b1b" className="letter" transform="translate(0 0)">
        <path
          fill="#FFFFFF"
          d="M1.47,6.7C2.21,5.82,3.26,5.26,4.4,5.12c1.26,0,4.04,1.83,4.04,3.54v6.52
		c0,1.67-2.78,3.57-4.22,3.57C2.71,18.73,0,16.84,0,15.19V0.36C0,0.16,0.15,0,0.35,0c0,0,0.01,0,0.01,0h0.74
		c0.2,0,0.36,0.15,0.36,0.35c0,0,0,0.01,0,0.01V6.7z M4.24,6.52c-0.9,0-2.78,1.24-2.78,2.3v6.21c0,0.97,1.69,2.32,2.75,2.32
		c0.97,0,2.75-1.26,2.75-2.32v-6.2C6.97,7.76,5.17,6.52,4.24,6.52L4.24,6.52z"
        />
      </g>
      <g id="b2e" className="letter" transform="translate(9.908 5.123)">
        <path
          fill="#FFFFFF"
          d="M8.42,6.55C8.35,6.91,8.06,7.2,7.7,7.27H1.49v2.6c0,1.11,1.78,2.37,2.75,2.37
		c1.03-0.19,2.01-0.61,2.87-1.22c0.25-0.13,0.5-0.11,0.61,0.13l0.27,0.45c0.1,0.19,0.03,0.42-0.16,0.52c0,0,0,0,0,0
		c-1.07,0.77-2.31,1.28-3.61,1.49C2.75,13.61,0,11.76,0,10.02V3.59C0,1.9,2.8,0,4.22,0s4.2,1.87,4.2,3.57L8.42,6.55z M1.49,3.75
		v2.28h5.48V3.7c0-1.06-1.78-2.33-2.75-2.33C3.27,1.4,1.49,2.69,1.49,3.75L1.49,3.75z"
        />
      </g>
      <g id="b3e" className="letter" transform="translate(19.816 5.123)">
        <path
          fill="#FFFFFF"
          d="M8.4,6.55C8.33,6.91,8.04,7.2,7.68,7.27H1.47v2.6c0,1.11,1.78,2.37,2.75,2.37
		c1.03-0.19,2.01-0.61,2.87-1.22c0.25-0.13,0.5-0.11,0.61,0.13l0.27,0.45c0.1,0.19,0.03,0.42-0.16,0.52c0,0,0,0,0,0
		c-1.06,0.79-2.29,1.31-3.59,1.51C2.75,13.64,0,11.78,0,10.05V3.59C0,1.9,2.8,0,4.22,0S8.4,1.87,8.4,3.54V6.55z M1.47,3.75v2.28
		h5.48V3.7c0-1.06-1.78-2.33-2.75-2.33C3.25,1.4,1.47,2.69,1.47,3.75L1.47,3.75z"
        />
      </g>
      <g id="bee" className="bee" transform="translate(28.258 1.433)">
        <path
          id="eye-r"
          className="eye"
          fill="#F2FF00"
          d="M18.24,7.39l-0.2-0.36c-0.06-0.1-0.16-0.17-0.27-0.2c-0.11-0.03-0.24-0.01-0.34,0.04l-2.51,1.44
		L14.9,8.34c-0.02,0.02-0.04,0.02-0.07,0.04l-0.02,0.02c-0.02,0.02-0.02,0.04-0.04,0.04l-0.02,0.02c-0.02,0.02-0.02,0.04-0.04,0.09
		l0,0c0,0.02-0.02,0.07-0.02,0.09l0,0v0.99c0.01,0.16,0.09,0.3,0.23,0.38l0.86,0.5c0.07,0.05,0.15,0.07,0.23,0.07
		c0.08,0,0.16-0.02,0.23-0.07l0.86-0.5c0.14-0.08,0.23-0.22,0.23-0.38V8.66c0-0.08-0.02-0.16-0.07-0.23l0.84-0.47
		c0.2-0.09,0.29-0.33,0.2-0.53C18.25,7.42,18.24,7.4,18.24,7.39z"
        />
        <path
          id="eye-l"
          className="eye"
          fill="#F2FF00"
          d="M9.75,8.68c0-0.04,0-0.07-0.02-0.09l0,0c0-0.02-0.02-0.04-0.04-0.09L9.66,8.48
		C9.64,8.45,9.64,8.43,9.61,8.43L9.59,8.41C9.57,8.38,9.55,8.38,9.52,8.36L9.5,8.34L7,6.9c-0.1-0.06-0.22-0.08-0.34-0.04
		c-0.11,0.03-0.21,0.1-0.27,0.2l-0.2,0.36C6.07,7.62,6.14,7.88,6.34,8l0.84,0.47C7.13,8.54,7.11,8.62,7.11,8.7v1
		C7.12,9.85,7.2,10,7.34,10.08l0.86,0.5c0.07,0.05,0.15,0.07,0.23,0.07c0.08,0,0.16-0.02,0.23-0.07l0.86-0.5
		C9.64,10,9.73,9.86,9.73,9.7L9.75,8.68L9.75,8.68z"
        />
        <path
          id="outline"
          className="outline"
          fill="#FFFFFF"
          d="M24.51,1.7c-1.06-0.79-2.29-1.31-3.59-1.51c-1.57,0.22-2.97,1.1-3.84,2.41L12.73,0.1
		c-0.23-0.14-0.52-0.14-0.74,0L7.63,2.61C6.75,1.3,5.35,0.42,3.79,0.19C2.49,0.4,1.26,0.91,0.2,1.7c-0.18,0.1-0.26,0.33-0.16,0.52
		l0.27,0.45c0.11,0.25,0.36,0.27,0.61,0.14C1.78,2.2,2.75,1.78,3.79,1.59C4.86,1.76,5.8,2.4,6.36,3.33L3.43,5.02
		C3.21,5.16,3.07,5.39,3.07,5.65v9.86c0,0.26,0.14,0.5,0.36,0.63l8.55,4.94c0.23,0.12,0.5,0.12,0.72,0l8.55-4.94
		c0.22-0.13,0.36-0.37,0.36-0.63V5.68c0-0.26-0.14-0.5-0.36-0.63l-2.91-1.69c0.56-0.94,1.5-1.58,2.57-1.76
		c1.03,0.19,2.01,0.61,2.87,1.22c0.25,0.14,0.5,0.11,0.61-0.14l0.27-0.45C24.75,2.03,24.68,1.81,24.51,1.7z M20.15,15.11l-7.81,4.51
		l-7.81-4.51v-9l7.81-4.51l7.81,4.51V15.11z"
        />
      </g>
      <g id="g1g" className="letter" transform="translate(52.812 5.101)">
        <path
          fill="#FFFFFF"
          d="M8.44,15.19c0,1.67-2.8,3.5-4.24,3.5c-1.23-0.16-2.4-0.6-3.43-1.29
		c-0.23-0.1-0.34-0.37-0.25-0.61l0.25-0.47c0.12-0.2,0.38-0.27,0.58-0.15c0.01,0,0.02,0.01,0.02,0.02c0.86,0.55,1.82,0.93,2.82,1.13
		c0.88,0,2.78-1.24,2.78-2.35v-2.91c-0.71,0.85-1.7,1.42-2.8,1.58c-1.51,0-4.18-1.9-4.18-3.54V3.57C0,1.83,2.84,0,4.22,0
		s4.22,1.83,4.22,3.57L8.44,15.19z M1.47,9.91c0,1.06,1.78,2.33,2.75,2.33s2.75-1.29,2.75-2.33V3.77c0-1.08-1.83-2.35-2.75-2.35
		S1.47,2.66,1.47,3.77V9.91z"
        />
      </g>
      <g id="g2r" className="letter" transform="translate(62.72 5.146)">
        <path
          fill="#FFFFFF"
          d="M5.35,1.31C5.3,1.54,5.1,1.6,4.88,1.56C4.56,1.49,4.25,1.44,3.93,1.42
		c-0.77,0-2.46,1.33-2.46,2.33v9.45c0.01,0.2-0.14,0.37-0.34,0.38c-0.01,0-0.01,0-0.02,0H0.36C0.17,13.6,0.01,13.46,0,13.27
		c0-0.02,0-0.05,0-0.07V3.54C0,1.96,2.62,0,3.9,0c0.43,0.03,0.86,0.1,1.29,0.2C5.4,0.27,5.53,0.5,5.46,0.72c0,0,0,0,0,0L5.35,1.31z"
        />
      </g>
      <g id="g3e" className="letter" transform="translate(68.588 5.123)">
        <path
          fill="#FFFFFF"
          d="M8.42,6.55C8.35,6.91,8.06,7.2,7.7,7.27H1.47v2.6c0,1.11,1.78,2.37,2.75,2.37
		c1.03-0.19,2.01-0.61,2.87-1.22c0.25-0.13,0.5-0.11,0.61,0.13l0.27,0.45c0.1,0.19,0.03,0.42-0.16,0.52c0,0,0,0,0,0
		c-1.06,0.79-2.29,1.31-3.59,1.51C2.75,13.64,0,11.78,0,10.05V3.59C0,1.9,2.8,0,4.22,0s4.2,1.87,4.2,3.57L8.42,6.55L8.42,6.55z
		 M1.49,3.75v2.28h5.48V3.7c0-1.06-1.78-2.33-2.75-2.33C3.27,1.4,1.49,2.69,1.49,3.75L1.49,3.75z"
        />
      </g>
      <g id="g4e" className="letter" transform="translate(78.496 5.123)">
        <path
          fill="#FFFFFF"
          d="M8.42,6.55C8.35,6.91,8.06,7.2,7.7,7.27H1.47v2.6c0,1.11,1.78,2.37,2.75,2.37
		c1.03-0.19,2.01-0.61,2.87-1.22c0.25-0.13,0.5-0.11,0.61,0.13l0.27,0.45c0.1,0.19,0.03,0.42-0.16,0.52c0,0,0,0,0,0
		c-1.05,0.79-2.29,1.31-3.59,1.51C2.75,13.64,0,11.78,0,10.05V3.59C0,1.9,2.8,0,4.22,0s4.2,1.87,4.2,3.57L8.42,6.55z M1.47,3.75
		v2.28h5.48V3.7c0-1.06-1.78-2.33-2.75-2.33C3.27,1.4,1.47,2.69,1.47,3.75L1.47,3.75z"
        />
      </g>
      <g id="g5n" className="letter" transform="translate(88.404 5.123)">
        <path
          fill="#FFFFFF"
          d="M6.88,3.81c0-1.17-1.83-2.42-2.71-2.42S1.47,2.64,1.47,3.81v9.41
		c0.01,0.2-0.14,0.37-0.34,0.38c-0.01,0-0.01,0-0.02,0H0.36c-0.2,0-0.36-0.16-0.36-0.36c0-0.01,0-0.01,0-0.02V3.61
		C0,1.9,2.8,0,4.2,0s4.2,1.9,4.2,3.63v9.59c0.02,0.19-0.12,0.36-0.31,0.38c-0.02,0-0.03,0-0.05,0H7.3c-0.2,0-0.36-0.16-0.36-0.36
		c0-0.01,0-0.01,0-0.02V3.81H6.88z"
        />
      </g>
    </svg>
  );
};

export default BeeGreen;
