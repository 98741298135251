import PropTypes from 'prop-types';

/**
 * <Icon />
 */

const Icon = ({ icon, layout, ariaHidden, ariaLabel }) => {
  const ariaProps = {
    'aria-hidden': (ariaHidden && !ariaLabel) || null,
    'aria-label': ariaLabel,
  };
  return <span {...ariaProps} className={`icon icon-${icon} ${layout ? `l-${layout}` : ''}`}></span>;
};

Icon.propTypes = {
  icon: PropTypes.oneOf([
    'facebook',
    'f',
    'instagram',
    'i',
    'twitter',
    't',
    'youtube',
    'y',
    'stamp',
    'stamp-plain',
    'postmark',
    'postmark-rect',
    'lock',
    'lock-open',
    'bars',
    'times',
    'envelope',
    'link',
    'phone',
    'megaphone',
    'page',
    'pencil',
    'play',
    'star-plain',
    'magnifier',
    'heart',
    'heart-plain',
    'finger-left',
    'finger-right',
    'arrow-right-curved',
    'arrow-right-circular',
    'arrow-left-dotted',
    'arrow-right-dotted',
    'circle',
    'circle-plus',
    'circle-minus',
    'circle-1',
    'circle-2',
    'circle-3',
    'circle-1-plain',
    'circle-2-plain',
    'circle-3-plain',
    'circle-info',
    'checkbox',
    'checkbox-checked',
    'list-star',
    'list-dot',
    'list-cross',
  ]).isRequired,
  layout: PropTypes.oneOf(['round', 'square-outline']),
  ariaHidden: PropTypes.bool,
  ariaLabel: PropTypes.string,
};
Icon.defaultProps = {
  ariaHidden: true,
};

export default Icon;
