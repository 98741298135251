import PropTypes from 'prop-types';
import { useRef, forwardRef } from 'react';
import { useTextField } from '@react-aria/textfield';

/** Components */
import Button from '../../Button';

const inputAttributes = {
  number: ['min', 'max', 'step'],
  tel: ['pattern'],
};

const TextField = forwardRef((props, textFieldRef) => {
  const fieldRef = useRef();
  const { labelProps, inputProps, errorMessageProps } = useTextField(props, fieldRef);
  const { label, type } = props;

  /** Map additional props not handled by React Aria */
  const additionalProps = {};
  if (type in inputAttributes) {
    inputAttributes[type].forEach((attribute) => {
      if (attribute in props) {
        additionalProps[attribute] = props[attribute];
      }
    });
  }

  return (
    <div ref={textFieldRef} className="form-field text">
      <label {...labelProps}>{label}</label>
      <div className="form-field-control">
        <input ref={fieldRef} {...inputProps} {...additionalProps} />
      </div>
      {props.errorMessage && (
        <Button {...errorMessageProps} onPress={() => fieldRef.current.focus()} className="form-field-error">
          {props.errorMessage}
        </Button>
      )}
    </div>
  );
});

TextField.displayName = 'TextField';

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email', 'password', 'number', 'url', 'tel', 'search']),
  errorMessage: PropTypes.string,
};

TextField.defaultProps = {
  type: 'text',
};

export default TextField;
