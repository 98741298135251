import { useEffect } from 'react';

/**
 * useOnResize Hook
 *
 * Call a given callback if the window is resized.
 * A delay is added to the resize handler for better performance.
 *
 * @param {function} callback  The callback function to call on resize
 */
const useOnResize = (callback) => {
  let isDelayed = false;

  /**
   * Resize handler
   */
  const resizeHandler = () => {
    if (!isDelayed) {
      isDelayed = true;
      setTimeout(() => {
        isDelayed = false;
        callback();
      }, 100);
    }
  };

  /** Bind/unbind the resize handler */
  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  });
};

export { useOnResize };
export default useOnResize;
