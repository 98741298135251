import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const noveltiesSlice = createSlice({
  name: 'novelties',
  initialState: {
    isLoaded: false,
    list: [],
    error: '',
    current: {},
  },
  reducers: {
    /**
     * Save the novelties list
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object[]} action.payload  The novelties list
     */
    saveList: (state, action) => {
      state.isLoaded = true;
      state.list = action.payload;
    },

    /**
     * Save the loading error
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the current novelty
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The current novelty
     */
    saveCurrent: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { saveList, saveError, saveCurrent } = noveltiesSlice.actions;

/**
 * Return the novelties list filtered by a given language
 *
 * @param {string} language  The novelties language
 * @return {object}
 */
export const selectList = createSelector(
  (state) => state.novelties,
  (_, language) => language,
  (novelties, language) => novelties.list.filter((novelty) => novelty.lang === language)
);

/**
 * Return a slice the novelties list filtered by a given language
 *
 * @param {string} language  The novelties language
 * @param {number} start  The slice start index
 * @param {number} length  The slice length
 * @return {object}
 */
export const selectSlice = createSelector(
  (state) => state.novelties,
  (_, language) => language,
  (_, __, start) => start,
  (_, __, ___, length) => length,
  (novelties, language, start, length) =>
    novelties.list
      .filter((novelty) => novelty.lang === language)
      .slice(start || 0, length ? (start || 0) + length : undefined)
);

export default noveltiesSlice.reducer;
