import { createSlice } from '@reduxjs/toolkit';

export const optionsSlice = createSlice({
  name: 'options',
  initialState: {
    contacts: {
      president: {
        firstName: '',
        lastName: '',
        address: '',
        zip: '',
        city: '',
        country: '',
        email: '',
        tel: '',
        mobile: '',
      },
      vicePresident: {
        firstName: '',
        lastName: '',
        address: '',
        zip: '',
        city: '',
        country: '',
        email: '',
        tel: '',
        mobile: '',
      },
      treasurers: [],
    },
    social: {
      facebook: '',
      twitter: '',
      instagram: '',
      youtube: '',
    },
    settings: {
      recaptcha_key: '',
    },
    error: '',
  },
  reducers: {
    /**
     * Save the options
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The options Objects
     * @param {Object} action.payload.contacts  The contacts
     * @param {Object} action.payload.social  The social contacts
     * @param {Object} action.payload.settings  The contacts settings
     */
    saveOptions: (state, action) => {
      state.contacts = action.payload.contacts;
      state.social = action.payload.social;
      state.settings = action.payload.settings;
    },

    /**
     * Save the loading error
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { saveOptions, saveError } = optionsSlice.actions;

export default optionsSlice.reducer;
