import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    isLoaded: false,
    list: [],
    error: '',
    current: { template: {} },
  },
  reducers: {
    /**
     * Save the pages list
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object[]} action.payload  The pages list
     */
    saveList: (state, action) => {
      state.isLoaded = true;
      state.list = action.payload;
    },

    /**
     * Save the loading error
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {String} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the current page
     *
     * @param {Object} state  The redux state
     * @param {Object} action  The reducer action
     * @param {Object} action.payload  The current page
     */
    saveCurrent: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { saveList, saveError, saveCurrent } = pagesSlice.actions;

/**
 * Select the front page
 *
 * @return {object}
 */
export const selectFrontPage = createSelector(
  (state) => state.pages,
  (pages) => pages.list.filter((page) => page.template.id === 'frontpage' && page.lang === 'fr')[0]
);

/**
 * Select a page by a given language
 *
 * @param {object} state  The React state
 * @param {string} template  The page template id
 * @param {string} language  The page language
 * @return {object}
 */
export const selectPage = createSelector(
  (state) => state.pages,
  (_, template) => template,
  (_, __, language) => language,
  (pages, template, language) => pages.list.filter((page) => page.template.id === template && page.lang === language)[0]
);

export default pagesSlice.reducer;
