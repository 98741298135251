import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectFrontPage, selectPage } from '../redux/pagesSlice';

/** Hooks */
import { useNavigateWithLoader } from '../hooks/useNavigateWithLoader';
import { useOnResize } from '../hooks/useOnResize';

/** Utils */
import { breakpointUp } from '../util/Breakpoint';

/** Components */
import Container from '../components/Container';
import Button from '../components/Button';
import Icon from '../components/Icon';
import ClubMenu from '../components/menus/Club';
import NewsMenu from '../components/menus/News';
import SearchMenu from '../components/menus/Search';
import MembersMenu from '../components/menus/Members';
import logoAP from '../images/logo-arlon-philatelie.png';
import pictoAP from '../images/picto-arlon-philatelie.png';

const Header = (props) => {
  const { navigate } = useNavigateWithLoader();
  const { t: __, i18n } = useTranslation();

  const isFrontPage = useSelector((state) => state.pages.current.template.id === 'frontpage');
  const frontPage = useSelector(selectFrontPage);
  const pageContact = useSelector((state) => selectPage(state, 'contact', i18n.language));

  /**
   * Handle the aria-expanded state of the menu
   */
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);
  const handleResize = () => setIsMenuExpanded(window.matchMedia(breakpointUp('sm')).matches);
  const handleMenuAction = () => setIsMenuExpanded(window.matchMedia(breakpointUp('sm')).matches);
  useOnResize(handleResize);
  useEffect(handleResize, []);

  return (
    <header className={`layout header ${isFrontPage ? 'is-front-page' : ''}`} role="banner">
      <Container>
        <div className="header-content">
          <h1 className="logo header-logo">
            <Button
              className="logo-button l-picto"
              cursor="press"
              onPress={() => navigate(frontPage.translations[i18n.language], 'tortilla')}
            >
              <span className="logo-picto">
                <img src={pictoAP} alt="Royal Philatelic Club Arlonais" />
              </span>
            </Button>
            {isFrontPage && (
              <Button
                className="logo-button l-image"
                cursor="press"
                onPress={() => navigate(frontPage.translations[i18n.language], 'tortilla')}
              >
                <span className="logo-image">
                  <img src={logoAP} alt="" />
                </span>
              </Button>
            )}
          </h1>
          <nav className="nav header-nav" aria-label={__('menu.Main menu')}>
            <Button
              layout="stamp"
              className="nav-toggle-button"
              cursor="press"
              ariaLabel={__('menu.Toggle menu')}
              aria-haspopup="true"
              aria-expanded={isMenuExpanded ? 'true' : 'false'}
              aria-controls="header-nav-primary"
              onPress={(e) => setIsMenuExpanded(e.target.getAttribute('aria-expanded') === 'false')}
            >
              <Icon icon="bars" ariaHidden={true} />
              <Icon icon="times" ariaHidden={true} />
            </Button>
            <ul id="header-nav-primary" className="nav-primary">
              <li>
                <ClubMenu onAction={handleMenuAction} />
              </li>
              <li>
                <NewsMenu onAction={handleMenuAction} />
              </li>
              <li>
                <SearchMenu onAction={handleMenuAction} />
              </li>
              <li>
                <div className="nav-item">
                  <Button
                    layout="stamp"
                    color="gold"
                    cursor="press"
                    className="nav-button contact-button"
                    onPress={() => {
                      handleMenuAction();
                      navigate(pageContact.translations[i18n.language], 'gold');
                    }}
                  >
                    {__('menu.Contact')}
                  </Button>
                </div>
              </li>
              <li>
                <MembersMenu onAction={handleMenuAction} />
              </li>
            </ul>
          </nav>
        </div>
      </Container>
    </header>
  );
};

export default Header;
