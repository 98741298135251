import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { saveUser, deleteUser, isLogged } from '../../redux/userSlice';

/** Hooks */
import { useReCaptcha } from '../../hooks/useReCaptcha';
import { useCsrfToken } from '../../hooks/useCsrfToken';
import { useFormValidation } from '../../hooks/useFormValidation';

/** Utils */
import { FormSender } from '../../util/Fetch';
import { setCookie, deleteCookie } from '../../util/Cookies';

/** Components */
import TextField from './fields/TextField';
import Button from '../Button';

/**
 * <Login />
 */

const Login = (props) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const __ = i18n.getFixedT(i18n.language, null, 'forms');

  /** CSRF Token, HTTP Referer & reCAPTCHA */
  const csrfToken = useCsrfToken('post-form-login');
  const httpReferer = useSelector((state) => state.pages.current.link);
  const reCaptchaKey = useSelector((state) => state.options.settings.recaptcha_key);
  const loadReCaptcha = useReCaptcha(reCaptchaKey);

  /** Form */
  const formRef = useRef();
  const formProps = {
    action: `${process.env.REACT_APP_REST_URL}login`,
    method: 'POST',
    autoComplete: 'off',
    noValidate: true,
    onFocus: loadReCaptcha,
    onSubmit: (e) => submitForm(e),
  };
  const fields = ['email', 'password'];
  const requiredFields = fields;
  const validateForm = useFormValidation(formRef.current, requiredFields);

  /** State */
  const [isLogging, setIsLogging] = useState(false);
  const isUserLogged = useSelector(isLogged);
  const user = useSelector((state) => state.user);
  const defaultMessages = { errors: '', status: isUserLogged ? `${__('Logged in as')} ${user.name}.` : '', form: '' };
  const [messages, setMessages] = useState(defaultMessages);

  /** Load Google reCAPTCHA if user is logged */
  isUserLogged && loadReCaptcha();

  /**
   * Submit the form
   *
   * Validate the form, Check Google reCAPTCHA and then send the form
   *
   * @param {SubmitEvent} e  The form submit event
   */
  const submitForm = (e) => {
    e.preventDefault();
    const [isValid, errors] = validateForm();
    setMessages({ ...defaultMessages, errors, form: isValid ? '' : __('The form contains errors') });

    if (isValid || isUserLogged) {
      window.grecaptcha.ready(() =>
        window.grecaptcha.execute(reCaptchaKey, { action: 'login' }).then((token) => sendForm(token))
      );
    }
  };

  /**
   * Send the form
   *
   * @param {string} captchaToken  The reCAPTCHA token
   */
  const sendForm = (captchaToken) => {
    /** Set the form data */
    const formData = new FormData(formRef.current);
    formData.append('action', !isUserLogged ? 'login' : 'logout');

    /** Create the user header string and remove email & password */
    const userHeader = isUserLogged
      ? `${user.email}:${user.token}`
      : `${formData.get('email')}:${formData.get('password')}`;
    if (!isUserLogged) {
      formData.delete('email');
      formData.delete('password');
    }

    /** Send the form */
    const sender = new FormSender(formData, formProps.action);
    sender.addHeader('X-CAPTCHA-TOKEN', captchaToken);
    sender.addHeader('X-CSRF-TOKEN', csrfToken);
    sender.addHeader('X-HTTP-REFERER', httpReferer);
    sender.addHeader('X-APP-USER', encodeURIComponent(window.btoa(userHeader)));
    sender.addListener('response', (data) => onSenderResponse(data));
    sender.send();
    props.onFocusChange(true);
    setIsLogging(true);

    /** Set the status message */
    setMessages({
      ...defaultMessages,
      status: !isUserLogged ? __('Authentication in progress') : __('Logout in progress'),
    });
  };

  /**
   * Process the form sender response
   *
   * @param {JSON} response
   */
  const onSenderResponse = (response) => {
    props.onFocusChange(false);
    setIsLogging(false);
    /** Successful authentication */
    if (response.sent && response.data.logged) {
      setCookie(process.env.REACT_APP_USER_COOKIE, JSON.stringify(response.data), 30);
      dispatch(saveUser(response.data));
      setMessages({ ...defaultMessages, status: `${__('Logged in as')} ${response.data.name}.` });
      props.onLogin();
    } else {
      /** Logging out */
      if (response.data && response.data.is_logging_out) {
        deleteCookie(process.env.REACT_APP_USER_COOKIE);
        dispatch(deleteUser());
        setMessages({ ...defaultMessages, status: '' });
        props.onLogout();
      } else {
        /** Display the error */
        setMessages({
          ...defaultMessages,
          form: response.data ? response.data.message : response.error ?? __('Error during authentication'),
        });
      }
    }
  };

  const hasErrorsClass = !isUserLogged && messages.form ? 'has-errors' : '';
  const hasStatusClass = !isUserLogged && messages.status ? 'has-status' : '';

  return (
    <form ref={formRef} {...formProps} className={`form login ${hasErrorsClass} ${hasStatusClass}`}>
      <h3 className="visually-hidden">{__('Login form')}</h3>
      <div className="form-wrap">
        <div className="form-section">
          {!isUserLogged ? (
            <>
              <TextField
                type="email"
                name="email"
                label={__('e-mail address')}
                placeholder={__('e-mail address')}
                errorMessage={messages.errors.email}
                isDisabled={isLogging}
                isRequired
                onFocus={() => props.onFocusChange(true)}
                onBlur={() => props.onFocusChange(false)}
              />
              <TextField
                type="password"
                name="password"
                label={__('Password')}
                placeholder={__('Password')}
                errorMessage={messages.errors.password}
                isDisabled={isLogging}
                isRequired
                onFocus={() => props.onFocusChange(true)}
                onBlur={() => props.onFocusChange(false)}
              />
            </>
          ) : (
            <p className="form-status">{messages.status}</p>
          )}
        </div>
        <div className="form-section">
          {!isUserLogged && (
            <>
              {messages.status && <p className="form-status">{messages.status}</p>}
              {messages.form && <p className="form-error">{messages.form}</p>}
            </>
          )}
          <div className="form-submit">
            <Button layout="plain" color="cedar" type="submit" className="submit-button" disabled={isLogging}>
              {!isUserLogged ? __('Login') : __('Logout')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

Login.propTypes = {
  onFocusChange: PropTypes.func,
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
};

Login.defaultProps = {
  onFocusChange: () => {},
  onLogin: () => {},
  onLogout: () => {},
};

export default Login;
