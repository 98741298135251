import { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Redux */
import { isLogged, deleteUser } from '../redux/userSlice';

/** Utils */
import { responseStatus } from '../util/Fetch';
import { deleteCookie } from '../util/Cookies';

/**
 * useAuth Hook
 *
 * Validate the application token for a user.
 *
 * @return {Object} return
 * @return {bool}   return.isAuth  Whether the token is valid
 * @return {string} return.message  The authentication message
 */
const useAuth = () => {
  const dispatch = useDispatch();

  const isUserLogged = useSelector(isLogged);
  const user = useSelector((state) => state.user);
  const [values, setValues] = useState({ isAuth: false, message: '' });
  const isLoading = useRef(false);

  /** Fetch the token from the app */
  useEffect(() => {
    if (!isLoading.current && isUserLogged) {
      isLoading.current = true;
      fetch(`${process.env.REACT_APP_REST_URL}auth`, {
        headers: {
          Accept: 'application/json',
          'X-APP-USER': encodeURIComponent(window.btoa(`${user.email}:${user.token}`)),
        },
      })
        .then(responseStatus)
        .then((response) => response.json())
        .then((response) => {
          if (!response.auth) {
            deleteCookie(process.env.REACT_APP_USER_COOKIE);
            dispatch(deleteUser());
          }
          return setValues({ isAuth: response.auth, message: response.message });
        })
        .catch(console.error);
    }
  }, [isLoading, isUserLogged, user, setValues, dispatch]);

  /** Return the values */
  return values;
};

export { useAuth };
export default useAuth;
