import { useState, useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

/** PropTypes */
import { pageListItemPropTypes } from '../util/propTypes';

/** Redux */
import { saveCurrent as saveCurrentPage } from '../redux/pagesSlice';

/** Utils */
import { responseStatus } from '../util/Fetch';

/** Hooks */
import { useMeta } from '../hooks/useMeta';
import { useNavigateWithLoader } from '../hooks/useNavigateWithLoader';

/** Components */
import Page404 from '../layouts/templates/Page404';

/**
 * <PagesRouter />
 */

const PagesRouter = () => {
  process.env.NODE_ENV === 'development' && console.info('<PagesRouter />');

  const pagesList = useSelector((state) => state.pages.list);
  const eventsList = useSelector((state) => state.events.list);

  return (
    <Routes>
      {pagesList.map((page, index) => (
        <Route key={`page-${index}`} path={page.link} element={<PageRoute page={page} />} />
      ))}
      {eventsList.map((event, index) => (
        <Route key={`event-${index}`} path={event.link} element={<PageRoute page={event} />} />
      ))}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

/**
 * <PageRoute />
 */

const PageRoute = ({ page }) => {
  process.env.NODE_ENV === 'development' && console.info('<PageRoute />');

  const dispatch = useDispatch();
  const setMeta = useMeta();
  const { removeLoader } = useNavigateWithLoader();
  const [templateContent, setTemplateContent] = useState(null);

  /**
   * Save the current page to Redux
   */
  useEffect(() => {
    dispatch(saveCurrentPage(page));
  }, [page, dispatch]);

  /**
   * Fetch the page content
   */
  useEffect(() => {
    setTemplateContent(null);

    fetch(page.api_url, { headers: { Accept: 'application/json' } })
      .then(responseStatus)
      .then((response) => response.json())
      .then((response) => {
        process.env.NODE_ENV === 'development' && console.info('<PageRoute /> - Page content fetched');

        /** Set the template content */
        setTemplateContent(response);

        /** Remove the loader */
        removeLoader();

        /** Update the page meta data */
        return setMeta({ ...response.meta_data, url: page.link });
      })
      .catch(console.error);
  }, [page, setTemplateContent, dispatch, setMeta, removeLoader]);

  /**
   * Load the template
   */
  const Template = lazy(() => import(`../layouts/templates/${page.template.name}`));

  return (
    <Suspense fallback={<></>}>
      <>{templateContent && <Template page={page} content={templateContent} />}</>
    </Suspense>
  );
};

PageRoute.propTypes = {
  page: pageListItemPropTypes,
};

export default PagesRouter;
