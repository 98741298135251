import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHover } from 'react-aria';
import { useMenuTriggerState } from '@react-stately/menu';
import { useMenuTrigger } from '@react-aria/menu';
import { useOverlay, DismissButton } from '@react-aria/overlays';
import { useButton } from '@react-aria/button';
import { FocusScope } from '@react-aria/focus';

/** Redux */
import { isLogged } from '../../redux/userSlice';

/** Hooks */
import { useAuth } from '../../hooks/useAuth';
import { useCloseWithTransition } from '../../hooks/useCloseWithTransition';
import { useOnScroll } from '../../hooks/useOnScroll';

/** Utils */
import { breakpointUp } from '../../util/Breakpoint';

/** Components */
import Login from '../forms/Login';
import Icon from '../Icon';

/**
 * <MembersMenu />
 */

const MembersMenu = (props) => {
  const { t: __ } = useTranslation();

  const menuRef = useRef();
  const buttonRef = useRef();
  const menuState = useMenuTriggerState({});
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, menuState, buttonRef);
  const { buttonProps } = useButton(menuTriggerProps, buttonRef);
  const closeWithTransition = useCloseWithTransition(menuRef, menuState.close, 250);
  const { hoverProps } = useHover({
    onHoverStart: (e) => window.matchMedia(breakpointUp('sm')).matches && menuState.open(),
    onHoverEnd: (e) => window.matchMedia(breakpointUp('sm')).matches && !hasFocus && closeWithTransition(),
  });

  const [hasFocus, setHasFocus] = useState(false);

  const isUserLogged = useSelector(isLogged);
  const { isAuth } = useAuth();

  return (
    <div {...hoverProps} ref={menuRef} className="nav-item">
      <button {...buttonProps} ref={buttonRef} className="button nav-button l-stamp c-cedar curs-up">
        <span className="button-text">
          {__('menu.Members')}
          <Icon icon={`lock${isUserLogged && isAuth ? '-open' : ''}`} />
        </span>
      </button>
      {menuState.isOpen && (
        <Overlay
          props={menuProps}
          autoFocus={menuState.focusStrategy}
          onClose={() => {
            menuState.close();
            setHasFocus(false);
          }}
          onAction={props.onAction}
          onFocusChange={setHasFocus}
        />
      )}
    </div>
  );
};

MembersMenu.propTypes = {
  onAction: PropTypes.func.isRequired,
};

/**
 * <Overlay />
 */

const Overlay = (props) => {
  const overlayRef = useRef();
  const closeWithTransition = useCloseWithTransition(overlayRef, props.onClose, 250);
  const { overlayProps } = useOverlay(
    {
      onClose: closeWithTransition,
      shouldCloseOnBlur: false,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  );
  useOnScroll(closeWithTransition);

  return (
    <FocusScope restoreFocus>
      <div ref={overlayRef} {...overlayProps} className="nav-overlay l-members">
        <DismissButton onDismiss={props.onClose} />
        <Login onFocusChange={props.onFocusChange} onLogin={closeWithTransition} onLogout={closeWithTransition} />
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
};

Overlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
};

export default MembersMenu;
