import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLink } from 'react-aria';
import { mergeProps } from '@react-aria/utils';

/** Hooks */
import { useNavigateWithLoader } from '../hooks/useNavigateWithLoader';

/** Components */
import Icon from './Icon';

/**
 * <Link />
 */

export const Link = forwardRef(
  ({ className, layout, url, target, title, lang, hrefLang, loaderColor, children, ...props }, linkRef) => {
    const { navigate } = useNavigateWithLoader(loaderColor);
    const { t: __ } = useTranslation();

    /** Remove the host from the url for local links */
    if (!process.env.REACT_APP_PUBLIC_URL.startsWith('/') && url.startsWith(process.env.REACT_APP_PUBLIC_URL)) {
      url = url.replace(process.env.REACT_APP_PUBLIC_URL, '/');
    }

    /** Aria-friendly onPress handler */
    const { linkProps } = useLink({
      onPress: props.onPress
        ? props.onPress
        : () => {
            if (target === '_blank') {
              window.open(url, '_blank');
            } else if (url.indexOf('tel:') > -1 || url.indexOf('mailto:') > -1) {
              window.open(url, '_self');
            } else if (url.startsWith('#')) {
              window.location.hash = url;
            } else {
              navigate(url);
            }
          },
    });

    const buttonClass = ['button-plain', 'button-stamp', 'button-plain-stamp'].includes(layout) ? 'button' : '';
    const targetProps = target === '_blank' ? { rel: 'noreferrer noopener', target } : {};
    const langProps = { lang, hrefLang };

    return (
      <a
        ref={linkRef}
        {...mergeProps(linkProps, targetProps, langProps)}
        href={url}
        className={`link ${buttonClass} ${className} ${layout ? `l-${layout.replace('button-', '')}` : ''}`}
        onClick={(e) => e.preventDefault()}
        onMouseEnter={(e) => props.onMouseEnter(e)}
        onMouseLeave={(e) => props.onMouseLeave(e)}
      >
        {children || title ? (
          children ?? title
        ) : (
          <>
            {__('buttons.Plus')} <Icon icon="plus" />
          </>
        )}
      </a>
    );
  }
);

Link.displayName = 'Link';

export const linkPropTypes = {
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  title: PropTypes.string,
};

Link.propTypes = {
  ...linkPropTypes,
  className: PropTypes.string,
  layout: PropTypes.oneOf(['underline', 'button-plain', 'button-stamp', 'button-plain-stamp', 'finger']),
  lang: PropTypes.string,
  hrefLang: PropTypes.string,
  loaderColor: PropTypes.string,
  onPress: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
Link.defaultProps = {
  className: '',
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default Link;
