import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHover } from 'react-aria';
import { useMenuTriggerState } from '@react-stately/menu';
import { useMenuTrigger } from '@react-aria/menu';
import { useOverlay, DismissButton } from '@react-aria/overlays';
import { useButton } from '@react-aria/button';
import { FocusScope } from '@react-aria/focus';
import { Item } from '@react-stately/collections';

/** Redux */
import { selectPage } from '../../redux/pagesSlice';

/** Hooks */
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';
import { useCloseWithTransition } from '../../hooks/useCloseWithTransition';
import { useOnScroll } from '../../hooks/useOnScroll';

/** Utils */
import { breakpointUp } from '../../util/Breakpoint';

/** Components */
import Menu from '../Menu';
import Link from '../Link';

/**
 * <ClubMenu />
 */

const ClubMenu = (props) => {
  const { t: __ } = useTranslation();

  const menuRef = useRef();
  const buttonRef = useRef();
  const menuState = useMenuTriggerState({});
  const { menuTriggerProps, menuProps } = useMenuTrigger({}, menuState, buttonRef);
  const { buttonProps } = useButton(menuTriggerProps, buttonRef);
  const closeWithTransition = useCloseWithTransition(menuRef, menuState.close, 250);
  const { hoverProps } = useHover({
    onHoverStart: (e) => window.matchMedia(breakpointUp('sm')).matches && menuState.open(),
    onHoverEnd: (e) => window.matchMedia(breakpointUp('sm')).matches && closeWithTransition(),
  });

  return (
    <div {...hoverProps} ref={menuRef} className="nav-item">
      <button {...buttonProps} ref={buttonRef} className="button nav-button l-stamp c-ruby curs-up">
        <span className="button-text">{__('menu.The club')}</span>
      </button>
      {menuState.isOpen && (
        <Overlay
          props={menuProps}
          autoFocus={menuState.focusStrategy}
          onClose={menuState.close}
          onAction={props.onAction}
        />
      )}
    </div>
  );
};

ClubMenu.propTypes = {
  onAction: PropTypes.func.isRequired,
};

/**
 * <Overlay />
 */

const Overlay = (props) => {
  const { navigate } = useNavigateWithLoader('ruby');
  const { t: __, i18n } = useTranslation();

  const overlayRef = useRef();
  const closeWithTransition = useCloseWithTransition(overlayRef, props.onClose, 250);
  const { overlayProps } = useOverlay(
    {
      onClose: closeWithTransition,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  );
  useOnScroll(closeWithTransition);

  const pageHistory = useSelector((state) => selectPage(state, 'club-history', i18n.language));
  const pageBoard = useSelector((state) => selectPage(state, 'club-board', i18n.language));
  const pageMembers = useSelector((state) => selectPage(state, 'club-members', i18n.language));
  const pageRegister = useSelector((state) => selectPage(state, 'club-register', i18n.language));

  return (
    <FocusScope restoreFocus>
      <div ref={overlayRef} {...overlayProps} className="nav-overlay l-club">
        <DismissButton onDismiss={props.onClose} />
        <Menu
          color="ruby"
          onAction={(link) => {
            closeWithTransition();
            navigate(link);
            props.onAction();
          }}
          aria-label={__('menu.The club')}
        >
          <Item key={pageHistory.link} textValue={pageHistory.title}>
            <Link url={pageHistory.link} onPress={() => {}} title={pageHistory.title} />
          </Item>
          <Item key={pageBoard.link} textValue={pageBoard.title}>
            <Link url={pageBoard.link} onPress={() => {}} title={pageBoard.title} />
          </Item>
          <Item key={pageMembers.link} textValue={pageMembers.title}>
            <Link url={pageMembers.link} onPress={() => {}} title={pageMembers.title} />
          </Item>
          <Item key={pageRegister.link} textValue={pageRegister.title}>
            <Link url={pageRegister.link} onPress={() => {}} title={pageRegister.title} />
          </Item>
        </Menu>
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
};

Overlay.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default ClubMenu;
