import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

/** Components */
import Background from '../components/Background';
import Container from '../components/Container';

/** PropTypes */
const commonPropTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
const commonDefaultProps = {
  className: '',
};

/**
 * <Template />
 */

export const Template = ({ template, layout, className, children }) => {
  process.env.NODE_ENV === 'development' && console.info(`<Template /> - ${template.name}`);

  return (
    <main className={`page template template-${template.id} ${className} ${layout ? `l-${layout}` : ''}`}>
      {children}
    </main>
  );
};

Template.propTypes = {
  ...commonPropTypes,
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  layout: PropTypes.oneOf(['']),
};
Template.defaultProps = commonDefaultProps;

/**
 * <TemplateHeader />
 */

export const TemplateHeader = ({ template, layout, color, className, title, subtitle, text }) => {
  const { t: __, i18n } = useTranslation();

  const titleKey = `template.${template.id.replace('-', '.')}.html_title`;
  title = i18n.exists(titleKey) ? __(titleKey) : title;

  className += subtitle || text ? ' l-cols' : '';

  return (
    <header className={`template-header ${className}`}>
      <Background layout="stamp-outline" color={color || 'cedar'}>
        <Container>
          <TemplateTitle>{title}</TemplateTitle>
          {(subtitle || text) && (
            <div className="template-header-text l-arrow">
              <p>
                {subtitle && <strong className="template-subtitle">{subtitle}</strong>}
                {subtitle && text && <br />}
                {text && text}
              </p>
            </div>
          )}
        </Container>
      </Background>
    </header>
  );
};

TemplateHeader.propTypes = {
  ...commonPropTypes,
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  color: PropTypes.oneOf(['', 'ruby', 'teal', 'peacock', 'gold', 'cedar']),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
};
TemplateHeader.defaultProps = commonDefaultProps;

/**
 * <TemplateTitle />
 */

export const TemplateTitle = ({ className, children }) => (
  <h2 className={`template-title ${className}`} dangerouslySetInnerHTML={{ __html: children }} />
);

TemplateTitle.propTypes = commonPropTypes;
TemplateTitle.defaultProps = commonDefaultProps;

/**
 * <TemplateContent />
 */

export const TemplateContent = forwardRef(({ className, children }, contentRef) => (
  <div ref={contentRef} className={`template-content ${className}`}>
    {children}
  </div>
));

TemplateContent.displayName = 'TemplateContent';
TemplateContent.propTypes = commonPropTypes;
TemplateContent.defaultProps = commonDefaultProps;

/**
 * <TemplateFooter />
 */

export const TemplateFooter = ({ className, children }) => (
  <footer className={`template-footer ${className}`}>{children}</footer>
);

TemplateFooter.propTypes = commonPropTypes;
TemplateFooter.defaultProps = commonDefaultProps;

export default Template;
