import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { saveCurrent as saveCurrentPage } from '../../redux/pagesSlice';

/** Hooks */
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';

/** Layouts */
import { Template, TemplateHeader, TemplateContent } from '../Template';

/** Components */
import Background from '../../components/Background';
import Container from '../../components/Container';
import Button from '../../components/Button';

/**
 * <Page404 />
 */

const Page404 = (props) => {
  const { navigate } = useNavigateWithLoader();
  const { t: __ } = useTranslation();
  const dispatch = useDispatch();
  const { removeLoader } = useNavigateWithLoader();

  const template = useMemo(() => ({ name: 'Page404', id: 'page-404' }), []);

  /**
   * Save the current page to Redux
   */
  useEffect(() => {
    dispatch(saveCurrentPage({ template }));
  }, [dispatch, template]);

  /** Remove the loader */
  useEffect(() => {
    removeLoader();
  }, [removeLoader]);

  return (
    <Template template={template}>
      <TemplateHeader
        template={template}
        layout="stamp-outline"
        color="peacock"
        title={__('template.404.Page not found')}
      />
      <TemplateContent>
        <Background layout="watercolor-paper">
          <Container className="text-center">
            <div className="content">
              <h3>{__('template.404.Page not found')}</h3>
              <p>{__('template.404.The page you are looking for does not exist')}</p>
              <Button layout="plain-stamp" color="teal" onPress={() => navigate('/')}>
                {__('template.404.Back to the front page')}
              </Button>
            </div>
          </Container>
        </Background>
      </TemplateContent>
    </Template>
  );
};

export default Page404;
