import PropTypes from 'prop-types';

/**
 * <Background />
 */

const Background = ({ layout, color, className, children }) => (
  <div className={`background ${layout ? `l-${layout}` : ''} ${color ? `c-${color}` : ''} ${className}`}>
    {children}
  </div>
);

Background.propTypes = {
  layout: PropTypes.oneOf([
    'stamp-outline',
    'stamps',
    'pale-gradient',
    'paper',
    'watercolor',
    'watercolor-paper',
    'watercolor-paper-orange',
  ]),
  color: PropTypes.oneOf(['ruby', 'teal', 'peacock', 'gold', 'cedar', 'cream']), // for "stamp-outline" and "pale-gradient" layouts
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
Background.defaultProps = {
  className: '',
};

export default Background;
